import { useEffect, useRef } from "react";
import { MediaDto } from "../../api/models";
import dashjs, { MediaPlayer } from 'dashjs';

interface VidePlayerProps{
    item: MediaDto;
    token:string|null;
    muted: boolean,
    controls: boolean,
    autoplay: boolean,
    onVideoPlay?: ()=>void;
    onVideoEnded?: ()=>void;
}

export default function VideoPlayer(props: VidePlayerProps){
    let { item, token} = props;
    let videoRef = useRef<HTMLVideoElement>(null);
    let playerRef = useRef<dashjs.MediaPlayerClass|null>(null)
    
    let poster = `${process.env.REACT_APP_API_URL}/api/v1/media/${item.id}/content/poster?jwt=${token}`;

    useEffect(()=>{
        if(videoRef.current){
            if(item.isMpegDashVideo){
                let player = MediaPlayer().create();  
                playerRef.current = player;
                player.extend("RequestModifier", function () {
                    return {    
                        modifyRequestHeader: function (xhr:XMLHttpRequest, arg:any) {
                            /* Add custom header. Requires to set up Access-Control-Allow-Headers in your */
                            /* response header in the server side. Reference: https://developer.mozilla.org/en-US/docs/Web/API/XMLHttpRequest/setRequestHeader */
                            /* xhr.setRequestHeader('DASH-CUSTOM-HEADER', 'MyValue'); */
                            xhr.setRequestHeader('Authorization', `Bearer ${token}`)
                            return xhr;
                        },                   
                        // modifyRequestURL: function (url: string) {   
                        //     if(url.endsWith('webm'))                      
                        //         return url + `?jwt=${token}`;
                        //     return url;
                        // }                      
                    };
                }, false);

                player.initialize(videoRef.current, `${process.env.REACT_APP_API_URL}/api/v1/media/${item.id}/mpd`, true);
                player.setMute(props.muted);                

                return ()=> player?.destroy()
            }else{
                videoRef.current.src = `${process.env.REACT_APP_API_URL}/api/v1/media/${item.id}/content/stream?&jwt=${token}`;
            }
        }
    },[item.id]);

    return (
        <video ref={videoRef}        
        poster={poster}                            
        preload="auto"
        autoPlay = {props.autoplay}
        muted = {props.muted}
        controls = {props.controls}
        onPlay={props.onVideoPlay}
        onEnded={props.onVideoEnded}
         />
    )
}