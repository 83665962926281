import { ThemeOptions } from '@mui/material/styles';
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';

declare module '@mui/material/Typography' {
    interface TypographyPropsVariantOverrides {
      fantasy: true;
    }
  }

let colors = {
    primary : '#826245',
    secundary: '#456582',
    background :  '#F1E8E0'
}

const themeOptions: ThemeOptions = {
  palette: {
    mode: 'light',
    primary: {
      main: colors.primary,  //'#3f51b5',      
      dark:  '#4a3122'      
    },
    secondary: {
      main: colors.secundary, //'#3f8cb5',
      dark: '#364961'
    },
    background:{
      paper: '#F8F4EF'      
    }
  },
  components:{
    MuiDrawer:{
        styleOverrides:{
            paper:{
                backgroundColor: colors.background
            }
        }
    },
    MuiTypography:{
        variants:[
            {
                props: { variant:'fantasy' },
                style: {
                  fontFamily: 'fantasy'                  
                },
              },
        ]
    }    
  }
};

const theme = createTheme(themeOptions);

export default theme;