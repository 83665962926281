import { Box, Button, CircularProgress, Grid, Toolbar } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { setSelectedAlbumId, useAlbums, invalidate } from "./ListAlbumSlice";
import styles from "./ListAlbum.module.css";
import * as Media from '../media/mediaSlice'
import clsx from "clsx";
import session from "../../app/Session";
import AlbumCard from "./AlbumCard";
import AddIcon from '@mui/icons-material/Add';
import { useTranslation } from 'react-i18next';
import { showAlbumModal } from '../../app/appSlice';

export default function ListAlbum() {
    const { t, i18n } = useTranslation();
    let { dispatch, state } = useAlbums();    
    let navigate = useNavigate();
    
    const token = session.getToken();

    function onView(albumId: string) {
        dispatch(setSelectedAlbumId(albumId));
        dispatch(Media.invalidate(true));
        dispatch(Media.setAlbumId({albumId}));
        navigate(`/album/${albumId}`, { replace: true });
    }

    async function deleteAlbum(albumId: string) {  
        dispatch(setSelectedAlbumId(undefined));    
        dispatch(invalidate(true));
    }

    return (
        <Box className={styles.root}>
            {state.invalidated === true && (
                <div className="progress-indicator">
                    <CircularProgress size="5rem" />
                </div>
            )}
            <Toolbar variant="dense">
                    <Box sx={{ flexGrow: 1 }}>
                        {session.canCreateMedia() && (
                            <Button
                                color="primary"
                                onClick={() => dispatch(showAlbumModal(true))}
                                startIcon={<AddIcon />}
                                size="large" >
                                {t('menu.new-album')}
                            </Button>
                        )}
                    </Box>               
            </Toolbar>
            <Grid container spacing={2} className={clsx(state.invalidated === true ? 'content-loading' : undefined, styles.container)} >
                {state.albums.map(item => {                 
                    return (
                    <Grid item key={item.id} xs={12} md={4} lg={3} >
                       <AlbumCard item={item} onView={onView} onDeleted={deleteAlbum} token={token} />
                    </Grid>
                    );
                })}
            </Grid>
        </Box>
    );
}