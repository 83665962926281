import { LoadingButton } from "@mui/lab";
import { Button, Grid, Paper, TextField } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect } from "react";
import { TokenRequest } from "../../api/models";
import SaveIcon from '@mui/icons-material/Save';
import { getService } from "../../app/api";
import { AuthService, UserService } from "../../api/services";
import { useLocation, useNavigate } from "react-router-dom";
import styles from "./Login.module.css";
import { useDispatch } from "react-redux";
import {setUser} from '../../app/appSlice';
import session from "../../app/Session";
import { useTranslation } from "react-i18next";


export default function Login() {
    const { t, i18n } = useTranslation();    
    let [item, setItem] = React.useState<TokenRequest>({ grandType: 'password', } as TokenRequest);
    let [loading, setLoading] = React.useState(false);
    let [authCode, setAuthCode] = React.useState<string|undefined>();
    let [useDeviceCode, setUseDeviceCode] = React.useState(false);
    let[deviceCode, setDeviceCode] = React.useState<string|null>("");

    let navigate = useNavigate();
    let dispatch = useDispatch();
    let location = useLocation();

    useEffect(()=>{
        if(authCode){
            handleAuthCode()    
        }else if(location.hash){
            let params = new URLSearchParams(location.hash.substring(1));
            let code = params.get('code');
            if(code){
                setAuthCode(code);
            }            
        }
    },[authCode]);

    const handleAuthCode = async () =>{
        const srv = getService(AuthService);  
        setLoading(true);
        try {
            let reply = await srv.getToken({ grandType:'code', code: authCode});
            if (reply) {
                if (reply.access_token) {                    
                    window.localStorage.setItem('token', reply.access_token);                    
                    await loadUser();
                }
                if (reply.refresh_token) {
                    window.localStorage.setItem('refresh-token', reply.refresh_token);
                }            
            }
        } finally {
            navigate('/', { replace: true });

            setAuthCode(undefined);
            setLoading(false);
        }
    }

    const handleLogin = async () => {
        const srv = getService(AuthService);
        setLoading(true);
        try {
            let reply = await srv.getToken(item);
            if (reply) {
                if (reply.access_token) {                    
                    window.localStorage.setItem('token', reply.access_token);                    
                    await loadUser();
                }
                if (reply.refresh_token) {
                    window.localStorage.setItem('refresh-token', reply.refresh_token);
                }
                navigate('/', { replace: true });
            }
        } finally {
            setLoading(false);
        }
    }

    const loadUser = async () =>{
        const srv = getService(UserService);
        let reply = await srv.getSessionUser();
        session.login(reply);
        dispatch(setUser(reply));
    }

    


    function setValue(field: keyof TokenRequest, e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
        setItem(prev => {
            return {
                ...prev,
                [field]: e.target.value
            } as TokenRequest
        });
    }

    return (
        <Paper elevation={3} style={{ padding: 32, margin: '32px', height: 'fit-content' }}>
            <Grid container spacing={1} className={styles.root} >
                <Grid item xs={12}>
                    <TextField required label="Username" fullWidth={true} onChange={e => setValue('username', e)} value={item.username} variant="filled" />
                </Grid>
                <Grid item xs={12}>
                    <TextField required type="password" label="Password" fullWidth={true} onChange={e => setValue('password', e)} value={item.password} variant="filled" />
                </Grid>
                <Grid item xs={12}>
                    <LoadingButton
                        variant="outlined"
                        loading={loading}
                        loadingPosition="start"
                        fullWidth={true}
                        startIcon={<SaveIcon />}
                        onClick={handleLogin}>
                        {t('login')}
                    </LoadingButton>                    
                </Grid>
                <Grid item xs={12}>
                    <Button style={{marginTop:'1rm'}} 
                            fullWidth={true} 
                            variant="outlined"
                            onClick={()=>setUseDeviceCode(true)} >
                            {t('login_code')}
                    </Button>
                </Grid>
                {useDeviceCode && (
                    <Grid item xs={12}>
                        <TextField 
                            label="Device Code" 
                            fullWidth={true} 
                            variant="filled" 
                            aria-aria-readonly={true}                            
                            value={deviceCode} />
                    </Grid>
                )}
            </Grid>
        </Paper>
    )
}