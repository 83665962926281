import * as React from 'react';
import { styled, alpha } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import InputBase from '@mui/material/InputBase';
import Badge from '@mui/material/Badge';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import AccountCircle from '@mui/icons-material/AccountCircle';
import MailIcon from '@mui/icons-material/Mail';
import MoreIcon from '@mui/icons-material/More';
import LogoutIcon from '@mui/icons-material/Logout';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { open } from '../drawer/drawerSlice';
import { Link, useNavigate } from 'react-router-dom';
import styles from "./Header.module.css";
import { Divider } from '@mui/material';
import session from '../../app/Session';
import { actionLogout } from '../../app/appSlice';
import { useTranslation } from 'react-i18next';

const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(3),
        width: 'auto',
    },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: '40ch',
        },
    },
}));

export default function Header() {
    const dispatch = useAppDispatch();
    let navigate = useNavigate();
    const { t, i18n } = useTranslation();
    let appState = useAppSelector(state=>state.app);
    let user = appState.user;

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [mobileMoreAnchorEl, setMobileMoreAnchorEl] =
        React.useState<null | HTMLElement>(null);

    const isMenuOpen = Boolean(anchorEl);
    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

    const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMobileMenuClose = () => {
        setMobileMoreAnchorEl(null);
    };

    const handleMenuClose = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(null);
        handleMobileMenuClose();

        switch(event.currentTarget.id){
            case 'logout':
                session.logout();
                dispatch(actionLogout());
                navigate('/', { replace: true });
                break;
            case 'profile':
                break;
        }
    };

    const handleMobileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        setMobileMoreAnchorEl(event.currentTarget);
    };

    let username = `${user?.email}`;

    const menuId = 'primary-search-account-menu';
    const renderMenu = (
        <Menu
            anchorEl={anchorEl}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            id={menuId}
            keepMounted
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            open={isMenuOpen}
            onClose={handleMenuClose}
        >   
            <div style={{padding:'0 1rem 0.5rem 1rem'}}>{username}</div>
            <Divider />
            <MenuItem id='profile' onClick={handleMenuClose}>{t('profile')}</MenuItem>
            <MenuItem id='logout' onClick={handleMenuClose}>{t('logout')}</MenuItem>
        </Menu>
    );

    const mobileMenuId = 'primary-search-account-menu-mobile';
    const renderMobileMenu = (
        <Menu
            anchorEl={mobileMoreAnchorEl}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            id={mobileMenuId}
            keepMounted
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            open={isMobileMenuOpen}
            onClose={handleMobileMenuClose}
        >
            <div style={{padding:'0 1rem 0.5rem 1rem'}}>{username}</div>
            <Divider />
            <MenuItem id='profile' onClick={handleMenuClose}>
                <IconButton
                    size="large"
                    aria-label="account of current user"
                    aria-controls="primary-search-account-menu"
                    aria-haspopup="true"
                    color="inherit"
                >
                    <AccountCircle />
                </IconButton>
                <p>{t('profile')}</p>
            </MenuItem>
            <MenuItem id='logout' onClick={handleMenuClose}>
                <IconButton size="large" aria-label="show 4 new mails" color="inherit">                    
                        <LogoutIcon />                    
                </IconButton>
                <p>{t('logout')}</p>
            </MenuItem>
        </Menu>
    );

    return (
        <>
            <AppBar position="static" className={styles.root}  >
                <Toolbar className={styles.toolbar} variant='dense' >
                    <IconButton
                        size="large"
                        edge="start"
                        color="inherit"
                        aria-label="open drawer"
                        sx={{ mr: 2 }}
                        onClick={(e) => dispatch(open(true))}
                    >
                        <MenuIcon />
                    </IconButton>
                    <div style={{display:'flex' ,alignItems:'center'}}>                        
                    <Link to="/" style={{ textDecoration: 'none', color: 'inherit' }} >
                        <Typography
                            className="App-logo"
                            variant="h6"
                            noWrap
                            component="div"                            
                            sx={{ display: { sm: 'block' } }}
                        > Memogram </Typography>                      
                    </Link>                    
                    {appState.sectionName && (
                        <div style={{display:'flex'}}>
                            <Typography style={{marginLeft: '0.5rem', marginRight:'0.5rem'}}>/</Typography>
                            <Typography >{t(appState.sectionName)}</Typography>
                           </div>
                        )} 
                    </div>                       
                    <Box sx={{ flexGrow: 1 }} >
                        {/* <Search>
                            <SearchIconWrapper>
                                <SearchIcon />
                            </SearchIconWrapper>
                            <StyledInputBase
                                placeholder="Search…"
                                style={{ width: '100%' }}
                                inputProps={{ 'aria-label': 'search' }}
                            />
                        </Search> */}
                    </Box>
                   { user && (
                    <>
                    <Box sx={{ display: { xs: 'none', md: 'flex' } }}>                
                        <IconButton
                            size="large"
                            edge="end"
                            aria-label="account of current user"
                            aria-controls={menuId}
                            aria-haspopup="true"
                            onClick={handleProfileMenuOpen}
                            color="inherit"
                        >
                            <AccountCircle />
                        </IconButton>
                    </Box>
                    <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
                        <IconButton
                            size="large"
                            aria-label="show more"
                            aria-controls={mobileMenuId}
                            aria-haspopup="true"
                            onClick={handleMobileMenuOpen}
                            color="inherit"
                        >
                            <MoreIcon />
                        </IconButton>
                    </Box>
                    </>
                   )} 
                </Toolbar>
            </AppBar>
            {renderMobileMenu}
            {renderMenu}
        </>
    );
}
